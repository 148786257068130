.RedocPage-header {
    position: fixed;
    top: 0;
    padding: 10px;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-bottom: 2px solid #f5f5f4;
    z-index: 999;
}

.RedocPage-header img {
    height: 60px;
    width: auto;
}

.RedocPage-header .select__api {
    max-width: 50vw;
    width: 200px;
    padding-right: 35px;
}

.react-tabs__tab-panel pre {
    max-height: 500px;
    overflow-y: scroll;
}

section.container__redoc {
    padding-top: 60px;
}